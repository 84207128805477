.formBackground {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url("../assets/images/background1.png") no-repeat center
    center/cover;
}

.logoContainer {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  width: 150px;
  height: auto;
}

.loginContainer {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.title {
  font-size: 2rem;
  color: #eb1362;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputGroup {
  margin-bottom: 20px;
}

.inputGroup label {
  display: block;
  font-size: 1rem;
  color: #333;
  margin-bottom: 8px;
}

.inputField {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s;
  background-color: #f5f5f5;
}

.inputField:focus {
  border-color: #eb1362;
  outline: none;
}

.loginButton {
  width: 100%;
  padding: 12px;
  background-color: #eb1362;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.loginButton:hover {
  background-color: #d11054;
}
