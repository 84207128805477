.homepageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: url("../assets/images/Background.png") no-repeat center
    center/cover;
  min-height: 100vh;
}

.welcomeMessage {
  font-size: 2.5rem;
  color: #eb1362; /* Pink color for the welcome message */
  margin-bottom: 20px;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.card {
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white */
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card h2 {
  font-size: 1.5rem;
  color: #eb1362; /* Pink color for headings */
  margin-bottom: 10px;
}

.card p {
  font-size: 2rem;
  color: #10131d; /* Blue color for numbers */
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
